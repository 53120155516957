/** --------------------------------------------------
*
*   Forgot password content
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/elements" as elements;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.back {
  @include elements.back-btn;
}

.title {
  margin-bottom: 3.6rem;
}

.notificationTitle {
  margin-bottom: 3rem;
}

.notificationText {
  color: vars.$color-text;
}
