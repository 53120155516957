/** --------------------------------------------------
*
*   Loading Indicator (form submit)
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.loader {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(vars.$color-white, 0.25)
}

.inner {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.circle {
  position: relative;
  width: 3rem;
  height: 3rem;
}
.circleItem {
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0.3rem;
  border: 0.3rem solid vars.$color-white;
  border-color: vars.$color-white transparent transparent transparent;
  border-radius: 50%;
  animation: ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.circleIem:nth-child(1) {
  animation-delay: -0.45s;
}
.circleIem:nth-child(2) {
  animation-delay: -0.3s;
}
.circleIem:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
