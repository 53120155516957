/** --------------------------------------------------
*
*   variables
*   BOX SHADOW
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

$box-shadow: 0 0 1rem 0.5rem rgba(#000, 0.2);
