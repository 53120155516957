/** --------------------------------------------------
*
*   Project detail - edit
*
*   TABLE OF CONTENTS
*   build btn
*   status
*   accordion content
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;


/** --------------------------------------------------
*   #build btn
*   -------------------------------------------------- */

.accordionContentItem {
  width: 100%;
  background-color: vars.$color-white;
}

.accordionContentTitle {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: vars.$site-padding--l;
  padding-left: vars.$site-padding--l;
  font-size: 1.6rem;
  background-color: vars.$color-athens-gray;
}

.accordionContentTable {
  border-collapse: collapse;
  margin-bottom: 3rem;
  border-right: 0.2rem solid vars.$color-athens-gray;
  border-left: 0.2rem solid vars.$color-athens-gray;
  border-bottom: 0.2rem solid vars.$color-athens-gray;

  tr {
    background-color: vars.$color-athens-gray;
  }

  th {
    padding: 0.8rem vars.$site-padding--l;
    border-bottom: 0.1rem solid vars.$color-white;
    background-color: vars.$color-athens-sand;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.33;
  }

  td {
    padding: vars.$site-padding--m vars.$site-padding--l;
    border-bottom: 0.1rem solid vars.$color-white;
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.accordionContentTotal {
  background-color: vars.$color-white;
  font-weight: 700;
}

.accordionContentForm {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: vars.$site-padding--l;
  padding-left: vars.$site-padding--l;

  >div {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      flex: 0 1 50%;
      font-weight: 700;
      padding-right: 1rem;
    }

    >div,
    >input {
      flex: 0 1 auto;
      text-align: center;
    }

    >input {
      flex-basis: 50%;
    }

  }

}

.accordionContentChosen {}

.modalButton {
  @include cta.buttonSkeleton;
  max-width: 5rem;
  transition: opacity .2s linear;

  &:hover {
    opacity: 0.5;
  }
}

.modalThumb {}
