/** --------------------------------------------------
*
*   CompanyDetails
*
*   TABLE OF CONTENTS
*   general
*   info
*   filter
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils" as utils;
@use "~/src/styles/mixins/layout" as layout;
@use "~/src/styles/mixins/elements" as elements;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.details {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;

  @include utils.mq(max m) {
    height: 100%;
  }
}




/** --------------------------------------------------
*   #info
*   -------------------------------------------------- */

.info {
  padding: vars.$site-padding--l;

  overflow-y: auto;
  @include elements.styledScrollbar(vars.$color-white, vars.$color-brand);
}

.logo {
  max-width: 14rem;
  margin-bottom: vars.$site-padding--m;
}

.header {
  &::after {
    display: block;
    content: '';
    background-color: vars.$color-line;
    width: 8rem;
    height: 0.4rem;
    margin: vars.$site-padding--l 0;
  }
}

.title {
  margin-bottom: vars.$site-padding--m;
}

.list {
  dt {
    padding-top: vars.$site-padding--m;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 700;
  }

  dd {
    padding-bottom: vars.$site-padding--m;

    &:not(:last-child) {
      border-bottom: 0.2rem solid vars.$edit-border-item;
    }
  }
}




/** --------------------------------------------------
*   #filter
*   -------------------------------------------------- */

.filter {
  margin-top: auto;
  padding: vars.$site-padding--l;
  border-top: 0.2rem solid vars.$filter-border;
}
