/** --------------------------------------------------
*
*   components - forms
*   FORM ELEMENTS
*
*   TABLE OF CONTENTS
*   form inputs
*   custom checkboxes
*   custom radiobuttons
*   form buttons
*
*   -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../mixins/cta" as cta;


/** --------------------------------------------------
*   #form inputs
*   -------------------------------------------------- */

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="tel"],
input[type="url"],
input[type="search"],
textarea,
select {
  width: 100%;
  appearance: none;

  padding: 1rem vars.$site-padding;
  background-color: vars.$color-white;
  border: 0.1rem solid vars.$color-border-input;
  box-shadow: none;

  font-family: vars.$font-basic;
  font-size: 1.6rem;
  line-height: 1.5;
  color: vars.$color-text;

  transition: border-color 0.2s ease;

  &:focus {
    border-color: vars.$color-focus;
    outline: 0.2rem solid transparent;
  }
}

/* textarea */
/* ******** */

textarea {
  resize: vertical;
  min-height: 16rem;
  /* prevent white-space at the bottom of the textarea: */
  vertical-align: top;
}

/* selects */
/* ******* */

.select-wrapper {
  position: relative;
  background-color: vars.$color-white;
  border-radius: 0.5rem;
}

.select-wrapper select {
  background: transparent;
  padding-right: 3.6rem;
}

.select-wrapper__arrow {
  position: absolute;
  bottom: 1.8rem;
  right: vars.$site-padding--m;
  pointer-events: none;

  & svg {
    display: block;
    width: 1.3rem;
    height: auto;
  }
}

/* hide the select arrow in IE: */
.select-wrapper select::-ms-expand {
  display: none;
}

/* input placeholders */
/* ****************** */

::-webkit-input-placeholder {
  color: vars.$color-placeholder;
  opacity: 1;
  font-style: italic;
}

::-moz-placeholder {
  color: vars.$color-placeholder;
  opacity: 1;
  font-style: italic;
}

:-ms-input-placeholder {
  color: vars.$color-placeholder;
  opacity: 1;
  font-style: italic;
}

:-moz-placeholder {
  color: vars.$color-placeholder;
  opacity: 1;
  font-style: italic;
}

.small-select {

  label {
    margin-right: vars.$site-padding--s;
    font-size: 1.3rem;
    font-weight: 700;
  }

  .select-wrapper {
    flex: 1 1 auto;

    select {
      padding: 0.4rem 3.6rem 0.4rem vars.$site-padding--s;
      border: none;
      font-size: 1.2rem;
    }
  }

  .select-wrapper__arrow {
    bottom: 0.8rem;
  }

}




/** --------------------------------------------------
*   #custom checkboxes
*   -------------------------------------------------- */

.form-type-checkbox {
  position: relative;
  min-height: 3rem;

  /* input */
  /* ***** */

  /*
   * the actual checkbox input is hidden
   * -> do not use "display: none" or
   *    "visibility: hidden" to hide the element,
   *    because that will make it inaccessible
   *    for keyboard users
   */

  & > input {
    appearance: none;
    opacity: 0;
    position: absolute;
    top: 50%;
    margin-top: -1.5rem;
    left: 0;
    width: 3rem;
    height: 3rem;
    border: none;
    background: transparent;
  }

  /* label */
  /* ***** */

  & > label {
    display: inline-block;
    position: relative;
    margin: 0;
    padding-left: 4.5rem;
    cursor: pointer;

    /*
     * pseudo-elements for the checkbox-container and the
     * checkbox-image
     */

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
    }

    &:before {
      top: 50%;
      margin-top: -1.5rem;
      left: 0;
      width: 3rem;
      height: 3rem;
      border: 0.2rem solid #c9c9c9;
      background-color: #f9f2f2;
      transition: border-color 0.2s ease;
    }

    &:after {
      top: 0.5rem;
      left: 0.6rem;
      width: 1.8rem;
      height: 1rem;
      transform: rotate(-45deg);
      border: solid;
      border-width: 0 0 0.4rem 0.4rem;
      border-top-color: transparent;
      background: transparent;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease, visibility 0.2s ease;
    }


    /* don't allow links in label: */
    & a {
      display: none !important;
    }

  }

  /* & > label */

  /* focus + checked */
  /* *************** */

  & > input:focus + label {
    &:before {
      border-color: vars.$color-focus;
    }
  }

  & > input:checked + label {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

} /* .form-type-checkbox */




/** --------------------------------------------------
*   #custom radiobuttons
*   -------------------------------------------------- */

.form-type-radio {
  position: relative;
  min-height: 3rem;

  /* input */
  /* ***** */

  /*
   * the actual radiobutton input is hidden
   * -> do not use "display: none" or
   *    "visibility: hidden" to hide the element,
   *    because that will make it inaccessible
   *    for keyboard users
   */

  & > input {
    appearance: none;
    opacity: 0;
    position: absolute;
    top: 50%;
    margin-top: -1.5rem;
    left: 0;
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
  }

  /* label */
  /* ***** */

  & > label {
    display: inline-block;
    position: relative;
    margin: 0;
    padding-left: 4.5rem;
    cursor: pointer;

    /*
     * pseudo-elements for the radio button container and the
     * checked radio button
     */

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
    }

    &:before {
      top: 50%;
      margin-top: -1.5rem;
      left: 0;
      width: 3rem;
      height: 3rem;
      border: 0.2rem solid #c9c9c9;
      border-radius: 50%;
      background-color: #f9f2f2;
      transition: border-color 0.2s ease;
    }

    &:after {
      width: 0;
      height: 0;
      top: 50%;
      margin-top: -0.8rem;
      left: 0.7rem;
      border: 0.8rem solid currentColor;
      opacity: 0;
      visibility: hidden;
      border-radius: 50%;
      transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
      transform: scale(0.7);
    }

    /* don't allow links in label: */
    & a {
      display: none !important;
    }

  } /* & > label */

  /* focus + checked */
  /* *************** */

  & > input:focus + label {
    &:before {
      border-color: vars.$color-focus;
    }
    &:after {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  & > input:checked + label {
    &:after {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
} /* .form-type-radio */




/** --------------------------------------------------
*   #form buttons
*   -------------------------------------------------- */

.form-submit {
  @include cta.ctaPrimary;

  position: relative;

  &.form-submit--loader {
    span {
      visibility: hidden;
    }
  }

  &.form-submit--check {

    display: flex;
    flex-flow: row wrap;

    .form-submit__icon {
      margin-right: vars.$site-padding--m;
    }

  }
}
