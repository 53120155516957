/** --------------------------------------------------
*
*   Login
*
*   TABLE OF CONTENTS
*   general
*   image
*   form
*   logo
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout" as layout;
@use "~/src/styles/mixins/link" as link;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.page {
  height: 100vh;

  display: grid;
  grid-template-columns: repeat(13, [col-start] 1fr);
  grid-gap: 0 vars.$site-grid-gap;

  background-color: vars.$color-background;

  @include layout.contentWrapper;
  box-shadow: vars.$box-shadow;

  @include utils.mq(max l) {
    display: block; // Disable grid

    // Top blue bar
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      height: 8rem;
      background-color: vars.$color-background;
    }
  }

  // Old school media query (height based)
  @media screen and (max-height: 670px) {
    display: block; // Disable grid

    // Top blue bar
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      height: 8rem;
      background-color: vars.$color-background
    }
  }
}

.image {
  grid-column: col-start 1 / span 11;
  grid-row: 1 / 3;
  background-color: vars.$color-background-img;

  @include utils.mq(max l) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media screen and (max-height: 670px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.logo {
  grid-column: col-start 1 / span 4;
  grid-row: 3;
  background-color: vars.$color-white;

  @include utils.mq(max l) {
    position: relative;
    z-index: 10;
    width: 23rem;
  }

  @media screen and (max-height: 670px) {
    position: relative;
    z-index: 10;
    width: 23rem;
  }
}

.form {
  grid-column: col-start 9 / span 4;
  grid-row: 2;
  max-width: 52rem;
  position: relative;
  z-index: 1;

  @include utils.mq(max xxxl) {
    justify-self: end;
    width: 43rem;
  }

  @include utils.mq(max l) {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 4rem auto;
  }

  @media screen and (max-height: 670px) {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);
    width: 90%;
    margin: 4rem auto;
  }
}




/** --------------------------------------------------
*   #image
*   -------------------------------------------------- */

.image {
  height: calc(100vh - 16rem);
  position: relative;

  @include utils.mq(max l) {
    height: 100vh;
    position: fixed;
  }

  @media screen and (max-height: 670px) {
    height: 100vh;
  }

  picture {
    display: block;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
  }
}

.lines6 {
  position: absolute;
  top: 0;
  left: 6rem;
  transform: rotate(180deg);

  @include utils.mq(max l) {
    display: none;
  }
}

.lines5 {
  position: absolute;
  left: 6rem;
  bottom: 0;

  @include utils.mq(max xxl) {
    display: none;
  }
}




/** --------------------------------------------------
*   #form
*   -------------------------------------------------- */
.form {
  padding: 5.5rem;
  background-color: vars.$color-white;

  @include utils.mq(max l) {
    padding: 3.5rem;
  }

  @media screen and (max-height: 670px) {
    padding: 3.5rem;
  }
}

.title {
  margin-bottom: 3.6rem;
}

.link {
  @include link.link($icon: true, $color: vars.$color-link);
  margin-top: 1.5rem;

  svg {
    width: 1rem;
    margin-top: -0.2rem;
  }
}

.lines4 {
  position: absolute;
  top: 0;
  right: 4.5rem;
  transform: translateY(-50%) rotate(90deg);
  mix-blend-mode: difference;
}

.lines2 {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
}




/** --------------------------------------------------
*   #logo
*   -------------------------------------------------- */

.logo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  height: 16rem;
  padding: vars.$site-padding;

  a {
    display: block;
    width: 27.3rem;
    height: auto;
  }

  @include utils.mq(max l) {
    height: 8rem;
    padding: vars.$site-padding--mobile;

    a {
      width: 14rem;
    }
  }

  @media screen and (max-height: 670px) {
    height: 8rem;
    padding: vars.$site-padding--mobile;

    a {
      width: 14rem;
    }
  }

}
