/** --------------------------------------------------
*
*   base - components - modal
*   MODAL
*
*   TABLE OF CONTENTS
*   body class
*   overlay
*   modal
*   - general
*   - change client modal
*   - user info
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../mixins/cta" as cta;
@use "../../base/utils" as utils;
@use "../../mixins/elements" as elements;


/** --------------------------------------------------
*  #body class
*  -------------------------------------------------- */

.ReactModal__Body--open {
  overflow: hidden;
}




/** --------------------------------------------------
*  #overlay
*  -------------------------------------------------- */

.modal-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(vars.$color-black, 0.25);
}




/** --------------------------------------------------
*  #modal
*  -------------------------------------------------- */

// General
.modal {
  height: 100vh;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.modal__inner {
  position: relative;
  margin-right: 2rem;
  margin-left: 2rem;
}

.modal__content {
  overflow: auto;
  width: 59rem;
  max-height: 85vh;
  padding: 4rem 4rem 10rem 4rem;
  background-color: vars.$color-white;

  @include elements.styledScrollbar(vars.$color-white, vars.$color-brand);

  //transform: translateY(200%);
  //transition: transform 0.3s ease;

  @include utils.mq(max m) {
    width: 100%;
  }
}

.modal__title {
  margin-bottom: 2rem;
  font-size: 4rem;
  line-height: 1.2;
}

.modal__text {
  margin-bottom: vars.$site-padding;
}

.modal__confirm button {
  @include cta.ctaPrimary;
}

.modal__close {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.modal__close-btn {
  @include cta.buttonSkeleton;
  width: 100%;
  padding: vars.$site-padding 4rem;
  color: vars.$modal-fg-close-btn;
  background-color: vars.$modal-bg-close-btn;
  font-weight: 600;
  text-align: center;
  transition: color 0.2s ease;

  &:hover,
  &:focus {
    color: vars.$modal-fg-close-btn-focus;
  }
}

// Create project modal
.modal--create-project {}

// Change client modal
.modal--change {

  .modal__content {
    width: 45rem;

    @include utils.mq(max s) {
      width: 100%;
    }
  }

  .modal__title {
    font-size: 3.6rem;
  }

}


// User info modal
.modal--user-info {

  .modal__content {
    width: 45rem;

    @include utils.mq(max s) {
      width: 100%;
    }
  }

}


// Delete project
.modal--delete {

  .modal__confirm {
    @include elements.modal-btn--logout;
  }

}


// Modal lines
.modal__lines {
  position: absolute;
  top: 0;
  right: 5rem;
  transform: translateY(-50%) rotate(90deg);
  mix-blend-mode: difference;
}
