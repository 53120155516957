/** --------------------------------------------------
*
*   forms
*   GENERAL
*
*   TABLE OF CONTENTS
*   form item
*
*   -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils" as utils;
@use "../../mixins/cta" as cta;


/** --------------------------------------------------
*   #form item
*   -------------------------------------------------- */

.form-item {
  margin: 0 0 2rem 0;
  padding: 0;

  /* label */
  /* ***** */

  & label {
    display: block;
    color: vars.$color-label;
    font-family: vars.$font-basic;
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: normal;
    margin-bottom: 0.7rem;

    & span {
      color: vars.$color-required;
    }
  }

  /* description */
  /* *********** */

  & .description {
    margin-top: 0.4rem;
    font-size: 1.4rem;
  }

}

.form-item--double {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > div {
    flex: 0 1 46%;

    @include utils.mq(max m) {
      flex-basis: 100%;

      + div {
        margin-top: 2rem;
      }
    }
  }
}
.form-item--share {
  margin-top: 2rem;

  .form-item__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    margin-bottom: vars.$site-padding--m;

    div {
      flex: 1;
    }

    button {
      @include cta.buttonSkeleton;

      margin-left: vars.$site-padding--l;

      svg {
        width: 1.7rem;
        height: auto;
        fill: vars.$color-error;
        transition: fill 0.2s ease;
      }

      &:hover,
      &:focus {
        svg {
          fill: rgba(vars.$color-error, 0.7);
        }
      }
    }
  }

}

.form-item--required {

  label::after {
    content: '*';
    margin-left: 0.4rem;
    color: vars.$color-required;
  }

  label span {
    display: none;
  }

} /* .form-item */
