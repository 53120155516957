/** --------------------------------------------------
*
*   variables
*   MEASUREMENTS
*
*   TABLE OF CONTENTS
*   site widths
*   site padding
*   site grid
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*   #site widths
*   -------------------------------------------------- */

$site-width: 192rem;
$content-width: 114rem;




/** --------------------------------------------------
*   #site padding
*   -------------------------------------------------- */

$site-padding: 2.4rem;
$site-padding--mobile: 1.2rem;


$site-padding--s: 0.8rem;
$site-padding--m: 1.6rem;
$site-padding--l: 3.2rem;




/** --------------------------------------------------
*   #site grid
*   -------------------------------------------------- */

$site-grid-gap: 2.4rem;


