/** --------------------------------------------------
*
*   base - mixins - elements
*   Sidebar tab
*
*   TABLE OF CONTENTS
*   general
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils" as utils;
@use "../../mixins/cta" as cta;


/** --------------------------------------------------
*  #general
*  -------------------------------------------------- */

@mixin sidebar-tab {

  button {
    @include cta.buttonSkeleton;

    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;

    padding: 2rem 1rem;
    background-color: vars.$sidebar-bg-item;
    color: vars.$sidebar-fg-item;
    font-weight: 600;

    transition: background-color 0.2s ease;

    span {
      margin-top: 1.5rem;
    }

    &:hover,
    &:focus,
    &[data-selected='true'] {
      background-color: vars.$color-white;

      svg {
        fill: vars.$sidebar-fg-item-focus;
      }

    }
  }

  svg {
    fill: vars.$color-brand;
    transition: fill 0.2s ease;
  }
}
