/** --------------------------------------------------
*
*   mixins - layout
*   CONTENT WIDTH
*
*   TABLE OF CONTENTS
*   content width
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";




/** --------------------------------------------------
*  #content width
*  -------------------------------------------------- */

@mixin contentWidth() {
  $maxWidth: vars.$site-width;

  width: 100%;
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
}
