/** --------------------------------------------------
*
*   base - components - navigation
*   NAVIGATION
*
*   TABLE OF CONTENTS
*   project detail navigation
*
*  -------------------------------------------------- */


@use "../../variables" as vars;


/** --------------------------------------------------
*  #project detail navigation
*  -------------------------------------------------- */

.edit-box,
.view-box,
.share-lines {
  fill: vars.$color-brand;
}
