/** --------------------------------------------------
*
*   Create project
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.btn {
  @include cta.buttonSkeleton;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  padding: vars.$site-padding 6.2rem vars.$site-padding vars.$site-padding--l;
  background-color: vars.$color-bg-submit;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    background-color: vars.$color-bg-submit-focus;
  }

  @include utils.mq(max l) {
    padding: 1.8rem 4.2rem vars.$site-padding--m 2rem;
  }

  &[data-create-in-sidebar='true'] {
    width: 100%;
    padding: vars.$site-padding--m vars.$site-padding--l;
    border-radius: vars.$border-radius;

    .btnIcon {
      padding-right: 1.2rem;

      svg {
        width: 1.6rem;
        margin-top: -0.2rem;
      }
    }

    .btnText {
      font-size: 1.6rem;
    }
  }
}

.btnIcon {
  padding-right: vars.$site-padding;

  svg {
    width: 3rem;
    height: auto;
    fill: vars.$color-white;
  }

  @include utils.mq(max l) {
    padding-right: 1.8rem;

    svg {
      width: 2rem;
      margin-top: -0.4rem;
    }
  }

}

.btnText {
  color: vars.$color-white;
  font-size: 2.5rem;
  font-weight: 600;

  @include utils.mq(max l) {
    font-size: 2rem;
  }
}
