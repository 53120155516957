/** --------------------------------------------------
*
*   variables
*   FONTS
*
*   TABLE OF CONTENTS
*   base fonts
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*   #base fonts
*   -------------------------------------------------- */

$_font-poppins: 'Poppins', sans-serif;
$_font-myriadpro: 'Myriad Pro', sans-serif;
$_font-dmsans: 'DM Sans', sans-serif;

$font-basic: $_font-dmsans; //$_font-myriadpro;
$font-heading: $_font-dmsans; //$_font-poppins;
