/** --------------------------------------------------
*
*   Project detail
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout" as layout;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.project {
  height: 100vh;

  display: grid;
  grid-template-columns: repeat(13, [col-start] 1fr);
  grid-gap: 0 vars.$site-grid-gap;

  background-color: vars.$color-white;

  @include layout.contentWrapper;
  box-shadow: vars.$box-shadow;


  @include utils.mq(max s) {
    display: flex;
    flex-flow: column nowrap;
    grid-gap: unset;
    height: 100%;
    overflow-y: initial;
  }
}
