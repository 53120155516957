/** --------------------------------------------------
*
*   variables
*   BORDER RADIUS
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

$border-radius: 0.4rem;
