/** --------------------------------------------------
*
*   ProjectList
*
*   TABLE OF CONTENTS
*   general
*
*  -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*  #general
*  -------------------------------------------------- */

.projects {
  margin-bottom: 6.4rem;
}

.empty {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 13rem);
  padding: 10rem 50% 3rem 0;
  color: vars.$color-white;
  font-size: clamp(6rem, 15vw, 14.8rem);
  line-height: 0.85;
  font-weight: 600;
  white-space: pre-line;

  @include utils.mq(max m) {
    min-height: 0;
  }
}

.lines4 {
  position: absolute;
  top: 0;
  left: 4rem;

  img {
    display: block; // some weird bug? ;/
  }

  @include utils.mq(max xl) {
    display: none;
  }
}

.lines2 {
  position: absolute;
  top: 0;
  right: 5rem;
}

.lines5 {
  position: absolute;
  right: 7rem;
  bottom: 0;

  @include utils.mq(max xl) {
    display: none;
  }
}
