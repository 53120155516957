/** --------------------------------------------------
*
*   base - mixins - elements
*   SCROLLBARS
*
*   TABLE OF CONTENTS
*   styled scrollbar
*   hidden scrollbar
*
*  -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*  #styled scrollbar
*  -------------------------------------------------- */

@mixin styledScrollbar($background-color: vars.$color-white, $scrollbar-color: vars.$scroll-fg) {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-color rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border: 0.2rem solid $background-color;

    &:hover {
      background-color: $scrollbar-color;
    }
  }
}




/** --------------------------------------------------
*  #hidden scrollbar
*  -------------------------------------------------- */

@mixin hiddenScrollbar() {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
    border: 0;

    &:hover {
      background-color: transparent;
    }
  }
}
