/** --------------------------------------------------
*
*   Company list
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;
@use "~/src/styles/mixins/elements" as elements;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.wrapper {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 4.7rem;
    background: linear-gradient(360deg, rgba(vars.$color-white, 0.8) 0%, rgba(vars.$color-white, 0) 100%);
    pointer-events: none;
  }
}

.list {
  display: flex;
  flex-flow: column nowrap;

  overflow: auto;
  position: relative;
  max-height: 25rem;
  border-bottom: 0.1rem solid vars.$modal-border-list;
  margin-bottom: 3rem;

  @include elements.styledScrollbar(vars.$color-white, vars.$color-brand);
}

.item {
  @include elements.modal-btn;

  margin-bottom: vars.$site-padding--s;

  button {
    justify-content: space-between;
  }
}
