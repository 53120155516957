/** --------------------------------------------------
*
*   Login form
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.error {
  margin-bottom: 2rem;
  color: vars.$color-error;
  font-size: 1.4rem;
}
