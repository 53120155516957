/** --------------------------------------------------
*
*   Project detail - edit
*
*   TABLE OF CONTENTS
*   build btn
*   status
*   accordion content
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;


/** --------------------------------------------------
*   #build btn
*   -------------------------------------------------- */

.build {
  @include cta.buttonSkeleton;

  width: 100%;
  margin-bottom: vars.$site-padding--m;
  padding: vars.$site-padding--m vars.$site-padding--l 1.4rem vars.$site-padding--l;

  display: flex;
  flex-flow: row wrap;

  color: vars.$color-white;
  background-color: vars.$color-shamrock;
  border-radius: vars.$border-radius;
  font-weight: 600;
  transition: background-color 0.2s ease;

  svg {
    fill: vars.$color-white;
    margin-right: 1.2rem;
  }

  &:hover,
  &:focus {
    background-color: vars.$color-bg-submit-focus;
  }
}


/** --------------------------------------------------
*   #status
*   -------------------------------------------------- */

.updateAndStatus {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  margin-bottom: vars.$site-padding--m;
  padding: vars.$site-padding--m vars.$site-padding--l;

  background-color: vars.$edit-bg-item;
  border-radius: vars.$border-radius;
}

.latestUpdate {
  flex: 0 1 45%;
}

.label {
  margin-right: vars.$site-padding--s;
  font-size: 1.3rem;
  font-weight: 700;
}

.value {
  font-size: 1.3rem;
  font-weight: 400;
}

.status {
  flex: 0 1 50%;

  >div {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

}


/** --------------------------------------------------
*   #accordion content
*   -------------------------------------------------- */

.accordionWrapper {
  overflow: hidden;
}

.accordionContentItem {
  flex: 0 1 50%;
  padding: vars.$site-padding--m vars.$site-padding--l;
  border-bottom: 0.1rem solid vars.$color-white;
}

.accordionContent {
  display: flex;
  flex-flow: column wrap;
}

.accordionLabel {
  color: vars.$edit-fg-label;
  font-size: 1.3rem;
}

.accordionValue {
  font-size: 1.6rem;
  line-height: 1.5;
}
