/** --------------------------------------------------
*
*   ProjectOverview
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils" as utils;
@use "~/src/styles/mixins/layout" as layout;
@use "~/src/styles/mixins/elements" as elements;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.projects {
  height: 100vh;

  display: grid;
  grid-template-columns: repeat(13, [col-start] 1fr);
  grid-gap: 0 vars.$site-grid-gap;

  background-color: vars.$color-white;

  @include layout.contentWrapper;
  box-shadow: vars.$box-shadow;

  @include utils.mq(max m) {
    background-color: vars.$color-background;
  }

  @include utils.mq(max s) {
    display: flex;
    flex-flow: column nowrap;
    grid-gap: unset;
    height: 100%;
    overflow-y: initial;
  }
}

.responsiveWrapper {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 0 vars.$site-grid-gap;
  grid-column: col-start 2 / span 12;
  height: 100vh;

  @include utils.mq(max m) {
    display: flex;
    flex-flow: column nowrap;
    padding-right: vars.$site-grid-gap;
    overflow-y: auto;
    @include elements.styledScrollbar(vars.$color-brand, vars.$color-white);
  }

  @include utils.mq(max s) {
    height: 100%;
    margin-top: vars.$site-padding--l;
    padding-left: vars.$site-grid-gap;
    overflow-y: initial;
  }
}

.overview {
  grid-column: col-start 1 / span 8;
  background-color: vars.$color-background;
  margin: 0 (-#{vars.$site-grid-gap});
  padding: 0 6.4rem;

  overflow-y: auto;
  @include elements.styledScrollbar(vars.$color-brand, vars.$color-white);

  position: relative;

  @include utils.mq(max m) {
    order: 2;
    grid-column: col-start 1 / span 12;
    margin: 0;
    padding: 0;
    overflow-y: initial;
  }
}

.detail {
  grid-column: col-start 9 / span 4;
  background-color: vars.$color-white;

  @include utils.mq(max m) {
    order: 1;
    grid-column: col-start 1 / span 12;
  }
}
