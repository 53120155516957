/** --------------------------------------------------
*
*   Error banner
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.banner {
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  background-color: rgba(vars.$color-monza, 0.9);
  box-shadow: vars.$box-shadow;

  transition: transform 0.2s ease;

  &[data-visible='false'] {
    transform: translateY(-125%);
    visibility: hidden;
    transition: transform 0.2s ease, visibility 0.5s ease;
  }

  &[data-visible='true'] {
    transform: translateY(0);
  }
}

.message {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: vars.$site-padding--m vars.$site-padding--l;
  color: vars.$color-white;
  font-weight: 600;
}

.close {

  button {
    @include cta.buttonSkeleton;

    padding: 2rem;
    transition: background-color 0.2s ease;

    &:hover,
    &:focus {
      background-color: rgba(vars.$color-white, 0.1);
    }
  }

  svg {
    width: 2rem;
    height: auto;
    fill: vars.$color-white;
  }

}
