/** --------------------------------------------------
*
*   base - mixins - link
*   LINK
*
*   TABLE OF CONTENTS
*   general
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*  #button skeleton
*  -------------------------------------------------- */

@mixin link($icon: false, $color: vars.$color-brand) {
  display: inline-flex;
  text-decoration: none;
  font-family: vars.$font-basic;
  text-align: inherit;
  color: $color;

  span {
    position: relative;

    &::before {
      display: none; // WIP

      content: '';
      position: absolute;
      bottom: 0.2rem;
      left: 0;
      width: 100%;
      height: 0.1rem;
      background-color: $color;
      transform: translateY(0.3rem);
      opacity: 0;
      transition: transform 0.2s ease, opacity 0.2s ease;
    }
  }

  @if $icon == true {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    svg {
      width: 1.6rem;
      height: auto;
      margin-right: 1rem;
      fill: $color;
    }
  }

  &:hover,
  &:focus {
    span::before {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
