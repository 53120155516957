/** --------------------------------------------------
*
*   Dashboard
*
*   TABLE OF CONTENTS
*   general
*   image
*   logo
*   create
*   change
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout" as layout;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.page {
  height: 100vh;

  display: grid;
  grid-template-columns: repeat(13, [col-start] 1fr);
  grid-gap: 0 vars.$site-grid-gap;

  background-color: vars.$color-background;

  @include layout.contentWrapper;
  box-shadow: vars.$box-shadow;

  position: relative;

  @include utils.mq(max s) {
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    grid-template-columns: none;
    grid-gap: unset;

    position: relative;
    padding-bottom: 10rem;
    background-color: transparent;
    box-shadow: none;

    &::after {
      content: '';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(vars.$color-background, 0.5);
    }
  }
}

.sidebar {
  grid-row: 1 / 4;

  @include utils.mq(max s) {
    grid-row: unset;

    position: relative;
    margin-bottom: 4rem;
  }
}

.image {
  grid-column: col-start 5 / span 10;
  grid-row: 1 / 3;
  background-color: vars.$color-background-img;

  @include utils.mq(max s) {
    grid-column: unset;
    grid-row: unset;

    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.logo {
  grid-column: col-start 11 / span 4;
  grid-row: 3;
  background-color: vars.$color-white;

  @include utils.mq(max s) {
    grid-column: unset;
    grid-row: unset;

    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.info {
  color: vars.$color-white;
}

.lines1 {
  position: absolute;
  top: 0;
  left: 20rem;

  @include utils.mq(max s) {
    top: 100%;
    right: 0;
    left: 10rem;
  }
}

.lines8 {
  position: absolute;
  right: 10rem;
  bottom: 0;

  @include utils.mq(max s) {
    display: none;
  }
}




/** --------------------------------------------------
*   #image
*   -------------------------------------------------- */

.image {
  height: calc(100vh - 16rem);
  position: relative;

  picture {
    display: block;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
  }

  @include utils.mq(max s) {
    height: 100%;
    min-height: 100vh;
    position: absolute;
  }

}




/** --------------------------------------------------
*   #logo
*   -------------------------------------------------- */

.logo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  height: 16rem;
  padding: vars.$site-padding;

  @include utils.mq(max s) {
    height: 9rem;
    padding: 2rem;
  }

  a {
    display: block;
    width: 27.3rem;
    height: auto;

    @include utils.mq(max s) {
      width: 17rem;
    }
  }

}




/** --------------------------------------------------
*   #create
*   -------------------------------------------------- */

.create {
  grid-column: col-start 3 / span 6;
  grid-row: 2;

  display: grid;
  justify-items: start;
  align-content: end;

  z-index: 1;

  @include utils.mq(max s) {
    grid-column: unset;
    grid-row: unset;

    display: block;
    margin: 0 2rem 2rem 2rem;
  }
}

.title {
  margin-bottom: 2.4rem;
  font-size: clamp(5rem, 10vw, 18rem);
  line-height: 0.8;
  letter-spacing: -0.2rem;
  color: vars.$color-white;
  text-transform: uppercase;

  span {
    font-size: clamp(2rem, 10vw, 6.8rem);
  }

  @include utils.mq(max s) {
    margin-bottom: 1rem;
  }
}



/** --------------------------------------------------
*   #change
*   -------------------------------------------------- */

.change {
  grid-column: col-start 4 / span 5;
  grid-row: 3;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;

  padding-left: vars.$site-padding;

  @include utils.mq(max l) {
    grid-column: col-start 3 / span 6;
  }

  @include utils.mq(max s) {
    grid-column: unset;
    grid-row: unset;

    justify-content: flex-start;

    padding: 2rem;
  }
}

.company {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @include utils.mq(max s) {
    flex-flow: column nowrap;
  }
}

.info {
  padding-right: vars.$site-padding;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.33;

  @include utils.mq(max s) {
    margin-bottom: 1rem;
  }
}
