/** --------------------------------------------------
*
*   Project detail - visuals
*
*   TABLE OF CONTENTS
*   general
*   visuals
*   slider actions
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;
@use "~/src/styles/mixins/elements" as elements;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.wrapper {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 0 2.4rem;
  grid-column: col-start 2/span 12;
  height: 100vh;
}

.content {
  grid-column: col-start 1/span 12;

  position: relative;
  z-index: 20;
  background-color: vars.$color-background;
  margin: 0 0 0 -2.4rem;
  overflow: hidden;
  background-color: vars.$color-white;

  @include elements.styledScrollbar(vars.$color-white, vars.$color-brand);

  &[data-loading='false'] {
    padding: 6rem 7rem;
  }
}


/** --------------------------------------------------
*   #visuals
*   -------------------------------------------------- */

.visuals {
  overflow: hidden;
  height: 100vh;
  width: 100%;

  ul {
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;

    display: flex;
    flex-flow: row nowrap;

    li {
      flex: 0 0 100%;
      width: 100%;
      min-width: 0;
      height: 100%;
    }
  }

  picture {
    display: block;
    // height: 100%;
  }

  // img {
  //   height: 100%;
  //   width: 100%;
  //   object-fit: cover;
  // }

}

.swiper {
  >div>div {
    height: 100vh;
  }

  :global .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


/** --------------------------------------------------
*   #slider actions
*   -------------------------------------------------- */

.actions {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;

  ol {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;

    width: 8rem;
    height: 100%;

    list-style-type: none;
    text-align: center;

    @include utils.mq(max s) {
      width: 7rem;
    }
  }

}

.amount {
  padding: vars.$site-padding--s vars.$site-padding--m;
  background-color: vars.$slider-bg-amount;
  font-weight: 600;
}

.previous button,
.next button {
  @include cta.buttonSkeleton;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 18rem;
  background-color: vars.$color-white;
  color: vars.$slider-fg-btn;

  @include utils.mq(max s) {
    height: 9rem;
  }

  svg {
    width: 2.2rem;
    height: auto;
    transition: fill 0.2s ease;

    @include utils.mq(max s) {
      width: 1.4rem;
    }
  }

  &[disabled] {
    cursor: not-allowed;

    svg {
      fill: vars.$slider-fg-btn-disabled;
    }

    &:hover,
    &:focus {

      svg {
        fill: vars.$slider-fg-btn-disabled;
      }

    }

  }

  &:hover,
  &:focus {

    svg {
      fill: vars.$slider-fg-btn-focus;
    }

  }
}

.previous {

  svg {
    transform: rotate(180deg);
  }

}
