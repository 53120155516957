/** --------------------------------------------------
*
*   base
*   FONTS
*
*
*   TABLE OF CONTENTS
*   Poppins
*   Myriad Pro
*   DM Sans
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*  # Poppins
*  -------------------------------------------------- */

/**
* Poppins
*
* available from:
* https://fonts.google.com/specimen/Poppins
*
* webfont downloaded from:
* https://gwfh.mranftl.com/fonts/poppins?subsets=latin

* license:
* Open Font License
* https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL
*/

/* regular (400) */
/* ************* */

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/poppins-v20-latin-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* semibold (600) */
/* ********** */

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/poppins-v20-latin-600.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}




/** --------------------------------------------------
*  # Myriad pro
*  -------------------------------------------------- */

/**
* Myriad Pro
*
* available from:
* https://fonts.adobe.com/fonts/myriad
*
* webfont downloaded from:
* https://fonts.adobe.com/fonts/myriad

* license:
* Personal and commercial use
* https://fonts.adobe.com/fonts/myriad
*/

/* regular (400) */
/* ************* */

@font-face {
  font-family: 'Myriad Pro';
  src: url('/assets/fonts/myriad-pro/myriadpro-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* semibold (600) */
/* ********** */

@font-face {
  font-family: 'Myriad Pro';
  src: url('/assets/fonts/myriad-pro/myriadpro-600.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}




/** --------------------------------------------------
*  # DM Sans
*  -------------------------------------------------- */

/**
* DM Sans
*
* available from:
* https://fonts.google.com/specimen/DM+Sans
*
* webfont downloaded from:
* https://gwfh.mranftl.com/fonts/dm-sans?subsets=latin

* license:
* Open Font License
* https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL
*/

/* light (300) */
/* *********** */

@font-face {
  font-family: 'DM Sans';
  src: url('/assets/fonts/dm-sans/dm-sans-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


/* bold (700) */
/* ********** */

@font-face {
  font-family: 'DM Sans';
  src: url('/assets/fonts/dm-sans/dm-sans-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
