/** --------------------------------------------------
*
*   Sidebar
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/elements" as elements;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.sidebar {
  display: flex;
  flex-flow: column wrap;
  min-width: 12rem;
  height: 100%;

  @include utils.mq(max s) {
    flex-direction: row;
    width: 100%;
  }
}

.sidebarTab {
  @include elements.sidebar-tab;
  flex: 1;
}

.closeBtn.sidebarTab {
  max-height: 16rem;

  button {
    color: vars.$color-white;
    background-color: vars.$color-brand;

    &:hover,
    &:focus,
    &[data-selected='true'] {
      background-color: vars.$color-brand;

      svg {
        fill: vars.$sidebar-fg-item-focus;
      }
    }
  }

  svg {
    fill: vars.$color-white;
  }

}
