/** --------------------------------------------------
*
*   Project detail - header
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.title {
  font-size: 4.8rem;

  @include utils.mq(max s) {
    font-size: 3.2rem;
  }
}

.location {
  position: relative;
  display: block;
  padding-bottom: vars.$site-padding;
  margin-bottom: vars.$site-padding;
  color: vars.$teaser-location;
  font-size: 3.2rem;
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 8rem;
    height: 0.4rem;
    background-color: vars.$teaser-location;
  }

  @include utils.mq(max s) {
    font-size: 2.6rem;
  }
}

.actions {
  display: flex;
  flex-flow: column nowrap;

  margin-bottom: 2.4rem;
}

.buttonDuplicate,
.buttonRemove {
  @include cta.buttonSkeleton;

  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0.2rem;
  padding: 1.2rem 1.2rem 0.9rem 1.2rem;
  background-color: vars.$color-white;
  transition: background-color 0.2s ease;
  border-radius: vars.$border-radius;
  font-size: 1.4rem;

  svg {
    width: 1.6rem;
    height: auto;
    margin-top: -0.2rem;
    margin-right: 1.2rem;
  }

  &:hover,
  &:focus {
    background-color: vars.$action-bg-btn-focus;
  }
}

.buttonRemove {
  color: vars.$color-link;

  svg {
    fill: vars.$color-link;
  }

}
