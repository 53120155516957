/** --------------------------------------------------
*
*   base - mixins - elements
*   Modal button
*
*   TABLE OF CONTENTS
*   modal button
*   modal button logout
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils" as utils;
@use "../../mixins/cta" as cta;


/** --------------------------------------------------
*  #modal button
*  -------------------------------------------------- */

@mixin modal-btn {

  button {
    @include cta.buttonSkeleton;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: vars.$site-padding--m vars.$site-padding--l;
    background-color: vars.$modal-bg-list-btn;
    transition: background-color 0.2s ease;

    .text {
      color: vars.$modal-fg-list-btn;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1;
    }

    .icon {

    }

    &:hover,
    &:focus {
      background-color: vars.$modal-bg-list-btn-focus;
    }
  }

}


/** --------------------------------------------------
*  #modal button logout
*  -------------------------------------------------- */

@mixin modal-btn--logout {
  @include modal-btn;

  button {
    background-color: vars.$modal-bg-logout-btn;

    .text {
      color: vars.$color-white;
    }

    &:hover,
    &:focus {
      background-color: rgba(vars.$modal-bg-logout-btn, 0.85);
    }
  }

}

