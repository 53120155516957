/** --------------------------------------------------
*
*   Fullscreen Loader
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.loader {
  height: 100vh;
  background-color: vars.$color-background;
}

.inner {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.circle {
  position: relative;
  width: 8rem;
  height: 8rem;
}
.circleItem {
  position: absolute;
  width: 6.4rem;
  height: 6.4rem;
  margin: 0.8rem;
  border: 0.8rem solid vars.$color-white;
  border-color: vars.$color-white transparent transparent transparent;
  border-radius: 50%;
  animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.circleIem:nth-child(1) {
  animation-delay: -0.45s;
}
.circleIem:nth-child(2) {
  animation-delay: -0.3s;
}
.circleIem:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: vars.$color-white;
  font-weight: bold;
}
