/** --------------------------------------------------
*
*   User settings
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/elements" as elements;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.sidebarTab {
  @include elements.sidebar-tab;
  flex: 1;
  max-height: 16rem;

  svg {
    fill: none !important; // the svg works with strokes instead of fill..
  }
}
