/** --------------------------------------------------
*
*   Menu content
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/elements" as elements;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.hello {
  margin-bottom: vars.$site-padding--l;
  font-size: 4rem;
  line-height: 1.2;

  span {
    display: block;
  }

}

.list {
  display: flex;
  flex-flow: column nowrap;

  overflow: auto;
  position: relative;
  max-height: 25rem;
}

.item {
  @include elements.modal-btn;

  margin-bottom: vars.$site-padding--s;
}

.logout {
  @include elements.modal-btn--logout;
}
