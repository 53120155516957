/** --------------------------------------------------
*
*   Accordion
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.accordion {
  margin-bottom: vars.$site-padding--m;
}

.accordionHeader {
  position: relative;
  z-index: 20;
}

.btn {
  @include cta.buttonSkeleton;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  width: 100%;
  padding: vars.$site-padding--m vars.$site-padding--l;
  background-color: vars.$edit-bg-item;
  border-radius: vars.$border-radius;
  border: 0.2rem solid vars.$edit-border-item;
  transition: background-color 0.2s ease;

  &[aria-controls='offer-commercial-discount'] {
    opacity: 0.5;
  }

  &[aria-expanded='true'] {
    background-color: vars.$color-white;
    border-radius: vars.$border-radius vars.$border-radius 0 0;

    .icon {
      background-color: vars.$edit-bg-item;

      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.icon {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  margin-top: -0.1rem;
  margin-right: vars.$site-padding--m;

  width: 2.4rem;
  height: 2.4rem;
  background-color: vars.$color-white;
  border-radius: vars.$border-radius;
  transition: background-color 0.2s ease;

  svg {
    width: 0.6rem;
    height: auto;
    transition: transform 0.2s ease;
  }
}

.text {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
}

.change {
  position: absolute;
  top: 50%;
  right: vars.$site-padding--l;
  transform: translateY(-50%);

  @include utils.mq(max s) {
    position: static;
    top: auto;
    right: auto;
    transform: none;

    padding: vars.$site-padding--m vars.$site-padding--l;
    background-color: vars.$edit-bg-item;
  }
}

.accordionContent {
  background-color: vars.$edit-bg-item;
  visibility: hidden;
  opacity: 0;
  max-height: 0;

  transition: opacity 0.2s ease, max-height 0.2s ease;

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    max-height: 100%;
  }
}

.accordionContentList {
  display: flex;
  flex-flow: row wrap;
}
