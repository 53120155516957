/** --------------------------------------------------
*
*   base - mixins - elements
*   Back button
*
*   TABLE OF CONTENTS
*   general
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../mixins/cta" as cta;
@use "../../mixins/link" as link;


/** --------------------------------------------------
*  #general
*  -------------------------------------------------- */

@mixin back-btn {

  button,
  a {
    @include cta.buttonSkeleton;
    @include link.link($icon: true, $color: vars.$color-link);
    margin-top: -1.5rem;
    margin-bottom: 3.5rem;
    font-weight: 600;

    svg {
      width: 1rem;
      margin-top: -0.2rem;
    }
  }

}
