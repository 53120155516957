/** --------------------------------------------------
*
*   variables
*   COLORS
*
*   TABLE OF CONTENTS
*   named colors
*   base colors
*   text colors
*   placeholder colors
*   focus colors
*   form colors
*   modal colors
*   edit colors
*   filter colors
*   sidebar colors
*   change colors
*   teaser colors
*   scroll colors
*   download colors
*   slider colors
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*   #named colors
*   -------------------------------------------------- */

$color-regal-blue: #004072;
$color-shamrock: #2ed690;
$color-monza: #e30613;
$color-athens-sand: #f0f2f5;
$color-athens-gray: #f8f9fa;
$color-athens-ghost: #e1e3e9;
$color-midnight-blue: #003763;
$color-jungle-green: #2fb27c;
$color-chathams-blue: #184a75;

// Rebranding colors
$color-chathams-blue: #14387f;
$color-pomegrante: #f33524;

// Map rebranding colors
$color-regal-blue: $color-chathams-blue;
$color-monza: $color-pomegrante;



/** --------------------------------------------------
*   #base colors
*   -------------------------------------------------- */

$color-black: #000;
$color-white: #fff;

$color-brand: $color-regal-blue;

$color-background: $color-regal-blue;
$color-background-img: $color-midnight-blue;

$color-title: $color-regal-blue;

$bg-body: $color-athens-gray;

$color-line: $color-monza;




/** --------------------------------------------------
*   #text colors
*   -------------------------------------------------- */

$color-text: $color-regal-blue;




/** --------------------------------------------------
*   #link colors
*   -------------------------------------------------- */

$color-link: $color-monza;




/** --------------------------------------------------
*   #placeholder colors
*   -------------------------------------------------- */

$color-placeholder: $color-black;




/** --------------------------------------------------
*   #focus colors
*   -------------------------------------------------- */

$color-focus: $color-regal-blue;




/** --------------------------------------------------
*   #form colors
*   -------------------------------------------------- */

$color-border-input: $color-athens-ghost;

$color-label: $color-regal-blue;
$color-required: $color-monza;
$color-error: $color-monza;

$color-bg-submit: $color-shamrock;
$color-bg-submit-focus: $color-jungle-green;






/** --------------------------------------------------
*   #modal colors
*   -------------------------------------------------- */

$modal-bg-list-btn: $color-athens-gray;
$modal-bg-list-btn-focus: rgba($color-regal-blue, 0.1);

$modal-fg-list-btn: $color-regal-blue;

$modal-bg-close-btn: $color-athens-gray;

$modal-fg-close-btn: rgba($color-regal-blue, 0.5);
$modal-fg-close-btn-focus: $color-regal-blue;

$modal-border-list: $color-athens-sand;

$modal-bg-logout-btn: $color-monza;




/** --------------------------------------------------
*   #edit colors
*   -------------------------------------------------- */

$edit-bg-item: $color-athens-gray;
$edit-border-item: $color-athens-gray;
$edit-fg-label: rgba($color-regal-blue, 0.4);

$action-bg-btn-focus: $color-athens-gray;

$change-fg-btn-focus: $color-monza;





/** --------------------------------------------------
*   #filter colors
*   -------------------------------------------------- */

$filter-bg: $color-chathams-blue;
$filter-border: $color-athens-gray;




/** --------------------------------------------------
*   #sidebar colors
*   -------------------------------------------------- */

$sidebar-bg-item: $color-athens-gray;
$sidebar-fg-item: $color-regal-blue;

$sidebar-fg-item-focus: $color-monza;




/** --------------------------------------------------
*   #change colors
*   -------------------------------------------------- */

$change-bg-btn: $color-athens-gray;
$change-fg-btn: $color-regal-blue;

$change-bg-btn-focus: rgba($color-athens-ghost, 0.4);




/** --------------------------------------------------
*   #teaser colors
*   -------------------------------------------------- */

$teaser-bg-btn: $color-athens-gray;
$teaser-fg-color: $color-regal-blue;

$teaser-fg-color-focus: $color-monza;

$teaser-border: $color-athens-gray;

$teaser-location: $color-monza;

$teaser-status: $color-athens-sand;
$teaser-status-icon: $color-shamrock;




/** --------------------------------------------------
*   #scroll colors
*   -------------------------------------------------- */

$scroll-fg: $color-monza;




/** --------------------------------------------------
*   #download colors
*   -------------------------------------------------- */

$download-bg-btn: $color-athens-gray;
$download-bg-btn-focus: rgba($color-athens-ghost, 0.3);
$download-fg-btn-focus: $color-monza;

$download-line: $color-athens-gray;




/** --------------------------------------------------
*   #slider colors
*   -------------------------------------------------- */

$slider-fg-btn: $color-regal-blue;
$slider-fg-btn-focus: $color-monza;
$slider-fg-btn-disabled: rgba($color-regal-blue, 0.5);

$slider-bg-amount: $color-athens-sand;
