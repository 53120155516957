/** --------------------------------------------------
*
*   base - mixins - elements
*   Change button
*
*   TABLE OF CONTENTS
*   general
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils" as utils;
@use "../../mixins/cta" as cta;


/** --------------------------------------------------
*  #general
*  -------------------------------------------------- */

@mixin change-btn($type: light-fg) {

  button {
    @include cta.buttonSkeleton;

    display: flex;
    flex-flow: row wrap;
    align-items: center;

    width: 100%;
    padding: 1rem vars.$site-padding--m vars.$site-padding--s vars.$site-padding--m;
    background-color: rgba(vars.$color-black, 0.1);
    border-radius: vars.$border-radius;
    transition: background-color 0.2s ease;

    .icon {
      padding-right: 1rem;

      svg {
        width: 1.5rem;
        height: auto;
        fill: vars.$color-white;
      }

    }

    .text {
      color: vars.$color-white;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.33;
    }

    &:hover,
    &:focus {
      background-color: rgba(vars.$color-black, 0.3);
    }

    @if $type == dark-fg {
      padding: vars.$site-padding--m vars.$site-padding--l;
      background-color: vars.$change-bg-btn;

      .icon {
        padding-right: vars.$site-padding--m;

        svg {
          fill: vars.$change-fg-btn;
        }

      }

      .text {
        color: vars.$change-fg-btn;
        font-size: 1.6rem;
      }

      &:hover,
      &:focus {
        background-color: vars.$change-bg-btn-focus;
      }
    }
  }

}
