/** --------------------------------------------------
*
*   Project detail - layout
*
*   TABLE OF CONTENTS
*   general
*   image
*   logo
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/elements" as elements;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.wrapper {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 0 2.4rem;
  grid-column: col-start 2/span 12;
  height: 100vh;
}

.content {
  grid-column: col-start 1/span 8;

  position: relative;
  z-index: 20;
  background-color: vars.$color-background;
  margin: 0 -2.4rem;
  overflow-y: auto;
  background-color: vars.$color-white;

  overflow-y: auto;
  @include elements.styledScrollbar(vars.$color-white, vars.$color-brand);

  @include utils.mq(max m) {
    grid-column: col-start 1/span 12;

    margin: 0 0 0 -2.4rem;
  }

  &[data-loading='false'] {
    padding: 6rem 7rem;
  }
}


/** --------------------------------------------------
*   #image
*   -------------------------------------------------- */

.image {
  grid-column: col-start 9/span 4;

  position: relative;
  height: 100vh;
  background-color: vars.$color-white;

  @include utils.mq(max m) {
    display: none;
  }

  picture {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}




/** --------------------------------------------------
*   #logo
*   -------------------------------------------------- */

.logo {
  position: absolute;
  z-index: 20;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  a {
    display: block;
    margin-bottom: 4rem;
  }
}
