/** --------------------------------------------------
*
*   base - mixins - cta
*   CTA BASIC
*
*   TABLE OF CONTENTS
*   cta primary
*
*  -------------------------------------------------- */


@use "button-skeleton";
@use "../../variables" as vars;


/** --------------------------------------------------
*  #cta primary
*  -------------------------------------------------- */

@mixin ctaPrimary() {
  @include button-skeleton.buttonSkeleton();

  width: 100%;
  padding: 1.3rem 3rem;
  color: vars.$color-white;
  background-color: vars.$color-bg-submit;
  border-radius: vars.$border-radius;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    background-color: vars.$color-bg-submit-focus;
  }

  &[disabled] {
    background-color: rgba(vars.$color-bg-submit, 0.5);
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: rgba(vars.$color-bg-submit, 0.5);
    }
  }
}
