/** --------------------------------------------------
*
*   Project detail - share
*
*   TABLE OF CONTENTS
*   general
*   download buttons
*   share form
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.content {
  padding-top: vars.$site-padding;
}

.title {
  font-size: 4.2rem;

  @include utils.mq(max s) {
    font-size: 3.2rem;
  }
}

.location {
  position: relative;
  display: block;
  color: vars.$teaser-location;
  font-size: 3.2rem;
  font-weight: 600;

  @include utils.mq(max s) {
    font-size: 2.6rem;
  }
}

.empty {
  display: flex;
  align-items: center;
  width: 50%;
  min-height: calc(100vh - 13rem);
  color: #003763;
  font-size: clamp(6rem, 15vw, 14.8rem);
  line-height: 0.85;
  font-weight: 600;
  white-space: pre-line;
  margin-left: 3rem;

  @include utils.mq(max m) {
    min-height: 0;
  }
}


/** --------------------------------------------------
*   #download buttons
*   -------------------------------------------------- */

.noPDF,
.outdatedPDF {
  margin-bottom: vars.$site-padding--l;

  button {
    @include cta.buttonSkeleton;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.3rem vars.$site-padding--l;
    background-color: vars.$download-bg-btn;
    font-weight: 600;
    border-radius: vars.$border-radius;
    transition: background-color 0.2s ease;

    svg {
      width: 2rem;
      height: auto;
      margin-right: vars.$site-padding--m;
      margin-top: -0.3rem;
      transition: fill 0.2s ease;
    }

    &:hover,
    &:focus {
      background-color: vars.$download-bg-btn-focus;

      svg {
        fill: vars.$download-fg-btn-focus;
      }

    }

  }

}

.noPDFmessage,
.outdatedPDFmessage {
  display: none;
}

.downloadList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: vars.$site-padding--m;

  padding-bottom: vars.$site-padding--m;
  margin-bottom: vars.$site-padding--l;
  border-bottom: 0.2rem solid vars.$download-line;

  @include utils.mq(max xl) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include utils.mq(max s) {
    grid-template-columns: repeat(1, 1fr);
  }

  a,
  button {
    @include cta.buttonSkeleton;

    display: flex;
    gap: vars.$site-padding--m;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 4.4rem;
    padding: 1.3rem vars.$site-padding--l;
    background-color: vars.$download-bg-btn;
    font-weight: 600;
    text-align: center;
    border-radius: vars.$border-radius;
    transition: background-color 0.2s ease;

    @include utils.mq(max xl) {
      flex-flow: column wrap;
    }

    @include utils.mq(max s) {
      flex-flow: row wrap;
    }

    svg {
      transition: fill 0.2s ease;
    }

    &:hover,
    &:focus {
      background-color: vars.$download-bg-btn-focus;

      svg {
        fill: vars.$download-fg-btn-focus;
      }

    }

    &:disabled {
      background-color: rgba(vars.$color-regal-blue, 0.4);
    }
  }

}


/** --------------------------------------------------
*   #share form
*   -------------------------------------------------- */

.shareForm h2 {
  margin-bottom: 2rem;
}

.buttons {
  padding: vars.$site-padding--m 0;
  border-top: 0.2rem solid vars.$download-line;
  border-bottom: 0.2rem solid vars.$download-line;
}

.buttonAdd {
  @include cta.buttonSkeleton;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem vars.$site-padding--m vars.$site-padding--s vars.$site-padding--m;
  background-color: vars.$download-bg-btn;
  font-weight: 600;
  font-size: 1.3rem;
  border-radius: vars.$border-radius;
  transition: background-color 0.2s ease;

  svg {
    width: 1.5rem;
    height: auto;
    margin-right: vars.$site-padding--m;
    margin-top: -0.3rem;
  }

  &:hover,
  &:focus {
    background-color: vars.$download-bg-btn-focus;
  }
}
