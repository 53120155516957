/** --------------------------------------------------
*
*   ProjectFilter
*
*   TABLE OF CONTENTS
*   general
*   filter btn
*   filter list
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.filter {
  display: inline-block;
  margin-bottom: 2.4rem;
  position: relative;
  z-index: 1;
}




/** --------------------------------------------------
*   #filter btn
*   -------------------------------------------------- */

.filterBtn {
  @include cta.buttonSkeleton;

  display: flex;
  gap: vars.$site-padding--s;
  align-items: baseline;

  position: relative;
  z-index: 20;
  background-color: vars.$filter-bg;
  padding: 1.2rem 2.4rem;
  border: 0.1rem solid rgba(vars.$color-white, 0.2);
  border-top: 0;
  border-radius: 0 0 vars.$border-radius vars.$border-radius;

  &[aria-expanded="true"] {
    border-radius: 0;
  }

  .label {
    color: rgba(vars.$color-white, 0.7);
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .selected {
    color: vars.$color-white;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .icon {
    color: vars.$color-white;
  }
}




/** --------------------------------------------------
*   #filter list
*   -------------------------------------------------- */

.filterList {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-4rem);

  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: vars.$color-white;
  border-radius: 0 0 vars.$border-radius vars.$border-radius;
  transition: opacity 0.4s ease, transform 0.3s ease;

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  li {

    button {
      @include cta.buttonSkeleton;

      width: 100%;
      padding: 1rem 2.4rem 0.8rem 2.4rem;
      background-color: vars.$color-white;
      font-size: 1.2rem;
      line-height: 1.2;
      transition: background-color 0.1s ease;

      &:hover,
      &:focus {
        background-color: rgba(vars.$color-midnight-blue, 1);
        color: vars.$color-white;
      }
    }

    &[data-selected="true"] {
      button {
        font-weight: 700;
      }
    }

    &:not(:last-child) {
      border-bottom: 0.1rem solid rgba(vars.$color-midnight-blue, 0.1);
    }

  }
}
