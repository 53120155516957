/** --------------------------------------------------
*
*   Project detail - edit project button (accordion item)
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta" as cta;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.btn {
  @include cta.buttonSkeleton;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  padding: vars.$site-padding--s vars.$site-padding--m;
  background-color: vars.$color-white;
  border: 0.1rem solid vars.$edit-border-item;
  border-radius: vars.$border-radius;

  &:hover,
  &:focus {

    .icon svg {
      fill: vars.$change-fg-btn-focus;
    }

  }
}

.icon {
  margin-right: 1.2rem;

  svg {
    width: 1.4rem;
    height: auto;
    transition: fill 0.2s ease;
  }
}

.text {
  font-size: 1.3rem;
}
