/** --------------------------------------------------
*
*   base
*   GLOBAL
*
*   TABLE OF CONTENTS
*   html & body
*   titles
*
*   -------------------------------------------------- */


@use "sass:math";
@use "../variables" as vars;
@use "../base/functions";
@use "../base/utils";
@use "../mixins/layout";


/** --------------------------------------------------
*   #html & body
*   -------------------------------------------------- */

html {
  font-size: percentage(math.div(vars.$root-font-size, vars.$base-font-size));
  /* ios: prevent automatic font scaling in landscape while allowing user zoom: */
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: vars.$font-basic;
  font-size: functions.strip-unit(math.div(vars.$base-font-size, vars.$root-font-size)) * 1em;
  line-height: 1.5;
  background-color: vars.$bg-body;
  color: vars.$color-text;
}




/** --------------------------------------------------
*   #titles
*   -------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: vars.$color-title;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2.5rem;
  line-height: 1.28;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}
