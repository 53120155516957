/** --------------------------------------------------
*
*   ProjectTile
*
*   TABLE OF CONTENTS
*   teaser
*   image
*   content
*
*  -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils" as utils;


/** --------------------------------------------------
*  #general
*  -------------------------------------------------- */

.teaser {
  display: flex;
  flex-flow: row wrap;
  background-color: vars.$color-white;
  border-radius: vars.$border-radius;
  overflow: hidden;
  position: relative;

  max-height: 37.5rem;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
}




/** --------------------------------------------------
*  #image
*  -------------------------------------------------- */

.image {
  flex: 0 0 50%;
  max-height: 37.5rem;

  @include utils.mq(max xl) {
    flex: 0 0 100%;
    height: 26rem;
  }

  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  }
}




/** --------------------------------------------------
*  #content
*  -------------------------------------------------- */

.content {
  flex: 0 0 50%;
  display: flex;
  flex-flow: column wrap;

  @include utils.mq(max xl) {
    flex: 0 0 100%;
  }
}

.header {
  padding: 4rem vars.$site-padding--l vars.$site-padding--l;

  h2 {
    margin-bottom: 0.4rem;
  }
}

.locationStatus {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1.2rem;
}

.location {
  color: vars.$teaser-location;
  font-weight: 700;
}

.status {
  padding: 0.2rem vars.$site-padding--m 0.2rem 1rem;
  text-transform: capitalize;
  border: 0.1rem solid vars.$teaser-status;
  border-radius: 1.5rem;

  &::before {
    display: inline-block;
    content: '';
    background-color: vars.$teaser-status-icon;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: vars.$site-padding--s;
    border-radius: 50%;
  }
}


// properties
.properties {
  padding: 0 0 vars.$site-padding--l;

  li {
    display: flex;
    gap: vars.$site-padding--m;
    align-items: baseline;
    padding: vars.$site-padding--s vars.$site-padding--l;

    &:not(:last-child) {
      border-bottom: 0.2rem solid vars.$teaser-border;
    }

    svg {
      transform: scale(0.5);
    }
  }
}


// cta buttons
.ctaButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: vars.$teaser-bg-btn;
  margin-top: auto;

  a {
    flex: 0 1 33.33%;
    width: 33.33%;
    display: block;
    padding: 1.8rem;
    color: vars.$teaser-fg-color;
    transition: color 0.2s ease;

    svg {
      margin-right: auto;
      margin-left: auto;
    }

    &.ctaButtonEdit {
      &::after {
        content: '';
        display: block;
        position: absolute;
        inset: 0 0 0 0;
      }
    }

    &:not(.ctaButtonEdit) {
      z-index: 2;
    }

    &:hover,
    &:focus {
      color: vars.$teaser-fg-color-focus;
    }
  }
}
